export const patientES: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  banner: "Banner - HTML - ES - DTC - Brazil (PT)",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  footerRef: "DTC - Emerging Science - Reference - Brazil (PT)",
  faqSection: "Emerging Science FAQ Section - HTML - Brazil (PT)",
  learnMore: "Learn More HTML - ES - DTC - Retina Brazil (PT)",
  scienceAdv: "Science is advancing-Emerging Science - Brazil (PT)",
  whenYouTest: "Emerging Science When You Test Updated- Brazil (PT)",
  footerCallout: "Footer Callout Emerging science - HTML DTC Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  video1GTMLabel: "Genes 101",
  video2GTMLabel:  "Inheritance 101",
  videoTopPara: "Community Resources Video Thumbnail Heading HTML",
  crTitleCard: "Community Resources Title Card",
  titleCard: "Community Resources - Education, Support, & Community (Updated)",
  backToTopGTM: "back to top - emerging science",
  footerClassName: "emerging-science-footer"
}

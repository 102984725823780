import React from 'react';
import { graphql } from 'gatsby';
import { Layout, HTMLRenderer, PageWrapper } from '@retina-packages/retina-theme-bootstrap';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { FooterCalloutBlock } from '@retina-packages/retina-theme-bootstrap/packages/containers/FooterNavigationCallOutContainer/style';
import { reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import retinaConfig from '../../../utils/retinaConfigs';
import {
  deriveMultiLogo,
  deriveSiteMenus,
  footRefHTMLBuilder,
  fullHTMLDataBuilder,
} from "../../../utils";
import { EsHeaderContainer, HomePageFooterContainer } from '../../../css/common/style';
import { patientES } from './constants';
import {
  CommonParagraphEmergingScience,
  EmerFooterBlock,
  EmergingScienceWrapper,
  EsEmergingScienceWrapperContainer,
  InfoBoxWrapper,
  RepWrapper,
  WhenYoutest,
} from "./styles";

const EmergingScienceTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksCO = pgProp.nodePage.relationships.field_paragraphs_gcso
  const validCOBlocks = blocksCO.filter((x: any) => 'relationships' in x)

  const pgContextsCS = props.pageContext
  const htmlStaticFilesES = {
    nonSVGImages: pgContextsCS.nonSVGImages,
    svgMediaImages: pgContextsCS.svgMediaImages,
    allMediaDocument: pgContextsCS.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientES.mainMenu, siteFooterMenu: patientES.patFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientES.footer
  const exitPopupTitle = patientES.exitPopup
  const hcpSwitcherTitle = patientES.hcpSwitcher
  const topNavTitle = patientES.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks: validCOBlocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });
  const siteLogos = deriveMultiLogo({ blocks: validCOBlocks, title: patientES.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.banner })

  /**
   * Page specific data building
   * Strats here
   */
  const faqSectionHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.faqSection })
  const learnMoreHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.learnMore })
  const scienceAdvHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.scienceAdv })
  const whenYouTestHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.whenYouTest })
  const footerCalloutHTML = fullHTMLDataBuilder({ blocks: validCOBlocks, title: patientES.footerCallout })
  const footerRefHTML = footRefHTMLBuilder({ blocks: validCOBlocks, title: patientES.footerRef })
  const socialBlock = footRefHTMLBuilder({ blocks: validCOBlocks, title: patientES.socialBlock });
  /**
   * Ends here
   */

  return (
    <EsHeaderContainer>
      <HomePageFooterContainer>
        <EsEmergingScienceWrapperContainer className="emrwrappercontainer">
          <EmergingScienceWrapper className='br-emerging-wrapper'>

            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              socialBlock={socialBlock}
              footerData={footerText}
              audience={audience}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              siteLogos={siteLogos}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              backToTopContent={retinaConfig.backToTopContent}
              gtmBackToTopLabel={patientES.backToTopGTM}
              staticMediaFiles={htmlStaticFilesES}
              hcpValidate={retinaConfig.hcpValidate}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={patientES.footerClassName}
              footref={footerRefHTML}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
            >
              <PageWrapper className='pagewrapper pagewrapper-emerging'>
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesES} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                <RepWrapper className='o-container'>
                  <HTMLRenderer data={htmlStaticFilesES} html={faqSectionHTML} tagName='div' className='o-inner' />
                </RepWrapper>
                <InfoBoxWrapper className='infobox-text o-column--full infobox-text-reduce emerging-science-learn-more'>
                  <HTMLRenderer data={htmlStaticFilesES} html={learnMoreHTML} tagName='div' className='o-container' />
                </InfoBoxWrapper>
                <CommonParagraphEmergingScience className="emerging-science-common-paragraph">
                  <HTMLRenderer data={htmlStaticFilesES} html={scienceAdvHTML} />
                </CommonParagraphEmergingScience>
                <WhenYoutest className='o-container emerging-science-when-you-test'>
                  <HTMLRenderer data={htmlStaticFilesES} html={whenYouTestHTML} />
                </WhenYoutest>
                <EmerFooterBlock className='emr-footer-block'>
                  <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                    <HTMLRenderer html={footerCalloutHTML} data={htmlStaticFilesES} tagName='div' className="footer-block footer-block-bg" />
                  </FooterCalloutBlock>
                </EmerFooterBlock>
              </PageWrapper>
            </Layout>
          </EmergingScienceWrapper>
        </EsEmergingScienceWrapperContainer>
      </HomePageFooterContainer>
    </EsHeaderContainer>
  )
}

export default EmergingScienceTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... EmergingSciencePageQuery
      }
    }
  }
`
